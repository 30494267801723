<script lang="ts" setup>
import Vue, { computed, ref, watch } from 'vue'
import { useRouter } from 'vue-router/composables'
import bottomSheet from '/~/core/bottom-sheet'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import BaseTab from '/~/components/base/tabs/base-tab.vue'
import BaseTabs from '/~/components/base/tabs/base-tabs.vue'
import DrawerBankForm from '/~/components/drawer/components/payment-methods/forms/drawer-bank-form.vue'
import DrawerCardForm from '/~/components/drawer/components/payment-methods/forms/drawer-card-form.vue'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import { useProvider } from '/~/composables/provider'
import { useUI } from '/~/composables/ui'

const props = defineProps<{
  close?: () => void
  meta?: object
}>()

const router = useRouter()
const { paymentMethods, everyBankAccountPayable, isMethodAvailable, creating } =
  usePaymentMethods()
const { isBankAccountsNavEnabled } = useProvider()
const { drawerModalsTargetName } = useUI()

const showBankTab = computed(
  () =>
    everyBankAccountPayable.value && isShowMethod(PaymentMethodType.bankAccount)
)
const showCardTab = computed(() => isShowMethod(PaymentMethodType.creditCard))
const hasAvailableMethods = computed(
  () => showBankTab.value || showCardTab.value
)

const tab = ref(showCardTab.value ? 'card' : 'bank')

function isShowMethod(type: string) {
  return isMethodAvailable(type, FlowType.purchase)
}
async function onAdded(addedMethod) {
  const text =
    addedMethod.type === PaymentMethodType.bankAccount
      ? 'Bank account successfully added'
      : 'Credit card successfully added'
  const backRoute = props.meta?.back || { hash: '#cart-payment-methods' }

  if (
    addedMethod.type === PaymentMethodType.bankAccount &&
    isBankAccountsNavEnabled.value
  ) {
    Vue.notify({
      text: 'Your bank account verification has been initiated and an amount between $0.01 and $2.99 will appear on your statement. Please allow up to 24 hours and come back once you have located this amount in order to verify your Bank Account.',
      type: 'success',
      duration: 10000,
    })
  } else {
    Vue.notify({
      text,
      type: 'success',
      duration: 3000,
    })
  }

  await router.replace(backRoute)

  bottomSheet.show('method-verify', {
    to: drawerModalsTargetName.value,
    props: {
      method: addedMethod,
    },
  })
}

watch(
  () => paymentMethods.value.length,
  (newLength, oldLength) => {
    if (newLength > oldLength) {
      onAdded(paymentMethods.value[0])
    }
  },
  { deep: true }
)
</script>

<template>
  <base-aside-page title="Add Payment Method" no-padding>
    <base-tabs
      v-if="hasAvailableMethods"
      v-model="tab"
      :disabled="creating"
      class="h-full"
    >
      <base-tab
        v-if="showCardTab"
        name="Credit/Debit Card"
        value="card"
        class="px-5"
      >
        <drawer-card-form />
      </base-tab>

      <base-tab
        v-if="showBankTab"
        name="Bank Account"
        value="bank"
        class="px-5"
      >
        <drawer-bank-form />
      </base-tab>
    </base-tabs>

    <div v-else class="p-[30px] text-center font-bold">
      No payment methods available
    </div>
  </base-aside-page>
</template>
